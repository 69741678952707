function setTextBlockHeight(){
	var window_width = jQuery( window ).width();
	if(window_width > 1024){
		var text_height = 0;
		jQuery(".cnr-single-intro-copy").each(function(){
			var current_height = jQuery(this).height();
			if(current_height > text_height){
				text_height = current_height;
			}    
		});
		jQuery(".cnr-single-intro-copy").css('height', text_height+'px');
	} else {
		jQuery(".cnr-single-intro-copy").css('height', 'auto');
	}
}

jQuery(document).ready(function($) {
	if(window.innerWidth < 900) {
		$('.cnr-wrapper .cnr-columns-wrapper.columns-three').slick({
			arrows: false,
			dots: true,
			slidesToShow: 1,
		});
	}

	setTextBlockHeight();
	$(window).resize(function(){
		setTextBlockHeight();
	});
}); /* end of as page load scripts */